import * as React from "react"

const badgeOne = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  bottom: "10%",
  height: "100px",
  width: "100px",
  background: "#9EF1C8",
  borderRadius: "100px",
  boxShadow: "0px 54px 80px rgba(0, 0, 0, 0.08), 0px 22.5599px 33.4221px rgba(0, 0, 0, 0.0575083), 0px 12.0616px 17.869px rgba(0, 0, 0, 0.0476886), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.04), 0px 3.59106px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 1.49432px 2.21381px rgba(0, 0, 0, 0.0224916)"
}

const Badge = () => (
  <div style={badgeOne}>
    <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)" stroke="#3F3F3F" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
        <path d="M37.94 54.935c12.822-3.436 20.43-16.614 16.995-29.436C51.499 12.678 38.32 5.07 25.499 8.505 12.678 11.94 5.07 25.119 8.505 37.94 11.94 50.762 25.119 58.37 37.94 54.935z"/>
        <path d="M36.333 25.258l1.213 4.527-3.482.933M21.187 27.7c-.435-1.626.584-3.392 2.21-3.827 1.625-.436 3.39.584 3.826 2.209M40.224 22.598c-.436-1.625.584-3.39 2.209-3.826 1.625-.436 3.391.584 3.827 2.21M43.334 34.206c1.4 5.223-1.744 10.669-6.968 12.069-5.223 1.4-10.669-1.745-12.069-6.968l19.037-5.101v0z"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" transform="rotate(-15 53.102 3.853)" d="M0 0h50.472v50.472H0z"/>
        </clipPath
      ></defs>
    </svg>
  </div>
)

export default Badge
