import * as React from "react"
import styled from "styled-components"

// components
import Footer from "../components/footer"
import Badge from "../components/badge"
import SEO from "../components/seo"

// css
import "../components/layout.css"

// fonts
import "fontsource-montserrat/800-normal.css"
import "fontsource-montserrat/300-normal.css"
import "fontsource-montserrat/400-normal.css"
import "fontsource-source-serif-pro/200-normal.css"
import "fontsource-source-serif-pro/400-normal.css"
import "fontsource-source-serif-pro/600-italic.css"

// random
const Random = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    bottom: 4rem;
    right: 3rem;
  }
`

const RandomWrapper = styled.div`
  position: relative;
`

const RandomBadgeOne = styled.div`
  position: absolute;
  top: -2rem;
  left: -1rem;
`

const RandomBadgeTwo = styled.div`
`

const RandomButton = styled.button`
  animation: spin 10s infinite linear;
  zIndex: 10;
  width: 185px;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
`

// frame
const Frame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
`

const FrameWrapper = styled.div`
  position: relative;
  background: #FFF;
  margin: 0 auto;
  padding: 3rem 3rem 8rem 3rem;
  max-width: 60rem;
  @media (min-width: 768px) {
    padding: 4.5rem 5rem;
  }
`

const FrameButtons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -1rem;
  bottom: -4rem;
  @media (min-width: 768px) {
    flex-direction: row;
    bottom: -2rem;
    left: 5rem;
  }
`

const FrameButtonsItem = styled.div`
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
`

const frameTitleStyles = {
  marginBottom: "1.3rem",
  color: "#3F3F3F",
  fontFamily: "Montserrat",
  fontWeight: "800",
  fontStyle: "normal",
  fontSize: "0.8rem",
  textTransform: "uppercase",
  letterSpacing: "6px"
}

const frameTextStyles = {
  marginBottom: "1.2rem",
  fontSize: "2.6rem",
  lineHeight: "2.4rem",
  color: "#3F3F3F",
  fontFamily: "Source Serif Pro",  
  fontWeight: "300"
}

const frameBodyStyles = {
  fontSize: "1.2rem",
  lineHeight: "2.2rem",
  color: "#3F3F3F",
  fontFamily: "Montserrat",
  fontWeight: "400"
}

// styles
const pageStyles = {
  position: "relative",
  height: "100vh",
  backgroundColor: "#3F3F3F",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundSize: "cover",
  backgroundPosition: "center"
}

const ContainerOne = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 20rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-align: center;
  @media (min-width: 1200px) {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
`

const surpriseBadgeImage = {
  width: "174px",
  height: "174px"
}

const Lede = styled.div`
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 3rem;
  }
`

const HeadingOne = styled.p`
  margin-bottom: 1.2rem;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 0.8rem;
  font-style: normal;
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: 6px;
  @media (min-width: 768px) {
    font-size: 0.8rem;
  }
`

const TitleOne = styled.h1`
  margin-bottom: 2rem;
  color: #FFF;
  font-family: "Source Serif Pro";
  font-weight: 200;
  font-size: 3rem;
  line-height: 1.2;
  @media (min-width: 768px) {
    font-size: 5rem;
    line-height: 1.2;
  }
`
const LedeOne = styled.p`
  max-width: 35rem;
  margin: 0 auto;
  color: #FFF;
  font-family: "Montserrat";
  font-size: 0.8rem;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`

const FormGroup = styled.div`
  @media (min-width: 768px) {
    display: flex;
    margin: 0 auto;
    max-width: 29rem;
  }
`

const FormSelectWrapper = styled.div`
  margin-bottom: 2rem;
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
`

const FormLabel = styled.label`
  display: block;
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
  line-height: 2.2rem;
  color: #FFF;
  font-family: "Source Serif Pro";  
  font-weight: 300;
  @media (min-width: 768px) {
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }
`

const formSelectStyles = {
  marginRight: "1.2rem",
  padding: "1.5rem",
  backgroundColor: "#FFF",
  width: "100%",
  border: "0",
  borderRadius: "0",
  fontFamily: "Montserrat",
  fontWeight: "400",
  backgroundImage: `url('data:image/svg+xml,%3Csvg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 1L5.984 6 1 1" stroke="%233F3F3F" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round"/%3E%3C/svg%3E')`,
  backgroundRepeat: "no-repeat, repeat",
  backgroundPosition: "right .7em top 50%, 0 0",
  backgroundSize: ".65em auto, 100%"
}

const Button = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  color: #FFF;
  border: 0;
  background: #5D21D0;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 0.8rem;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 6px;
  box-shadow: 0px 54px 80px rgba(0, 0, 0, 0.08), 0px 22.5599px 33.4221px rgba(0, 0, 0, 0.0575083), 0px 12.0616px 17.869px rgba(0, 0, 0, 0.0476886), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.04), 0px 3.59106px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 1.49432px 2.21381px rgba(0, 0, 0, 0.0224916);
  @media (min-width: 768px) {
    width: auto;
  }
  &:hover,
  &:focus {
    background: #2204AA;
  }
`

const ButtonA = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  color: #FFF;
  border: 0;
  background: #5D21D0;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 0.8rem;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 6px;
  box-shadow: 0px 54px 80px rgba(0, 0, 0, 0.08), 0px 22.5599px 33.4221px rgba(0, 0, 0, 0.0575083), 0px 12.0616px 17.869px rgba(0, 0, 0, 0.0476886), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.04), 0px 3.59106px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 1.49432px 2.21381px rgba(0, 0, 0, 0.0224916);
  text-decoration: none;
  @media (min-width: 768px) {
    width: auto;
  }
  &:hover,
  &:focus {
    background: #2204AA;
  }
`

const ButtonSecondary = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  color: #FFF;
  border: 0;
  background: #816AAC;
  cursor: pointer;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 0.8rem;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 6px;
  box-shadow: 0px 54px 80px rgba(0, 0, 0, 0.08), 0px 22.5599px 33.4221px rgba(0, 0, 0, 0.0575083), 0px 12.0616px 17.869px rgba(0, 0, 0, 0.0476886), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.04), 0px 3.59106px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 1.49432px 2.21381px rgba(0, 0, 0, 0.0224916);
  @media (min-width: 768px) {
    width: auto;
  }
  &:hover,
  &:focus {
    background: #2204AA;
  }
`

const buttonTextStyles = {
  marginRight: "1.2rem"
}

const srOnly = {
  position: "absolute",
  width: "1px",
  height: "1px",
  padding: "0",
  margin: "-1px",
  overflow: "hidden",
  clip: "rect(0,0,0,0)",
  border: "0"
}

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.restart = this.restart.bind(this);
    this.randomize = this.randomize.bind(this);
  }
  state = {
    backgrounds: [
      {
        name: 'a-battle',
        label: 'a battle'
      },
      {
        name: 'a-joke',
        label: 'a joke'
      },
      {
        name: 'about-nothing',
        label: 'about nothing'
      },
      {
        name: 'animated',
        label: 'animated'
      },
      {
        name: 'at-a-bar',
        label: 'at a bar'
      },
      {
        name: 'at-moes',
        label: 'at moes'
      },
      {
        name: 'barefoot',
        label: 'barefoot'
      },
      {
        name: 'between-two-ferns',
        label: 'between two ferns'
      },
      {
        name: 'big-enough',
        label: 'big enough'
      },
      {
        name: 'bouncy',
        label: 'bouncy'
      },
      {
        name: 'caffeinated',
        label: 'caffeinated'
      },
      {
        name: 'cooked',
        label: 'cooked'
      },
      {
        name: 'corny',
        label: 'corny'
      },
      {
        name: 'delicious',
        label: 'delicious'
      },
      {
        name: 'distractionless',
        label: 'distractionless'
      },
      {
        name: 'empty',
        label: 'empty'
      },
      {
        name: 'excellent',
        label: 'excellent'
      },
      {
        name: 'fine',
        label: 'fine'
      },
      {
        name: 'go-15-rounds',
        label: 'go-15-rounds'
      },
      {
        name: 'golden',
        label: 'golden'
      },
      {
        name: 'great-again',
        label: 'great again'
      },
      {
        name: 'grilled',
        label: 'grilled'
      },
      {
        name: 'hairy',
        label: 'hairy'
      },
      {
        name: 'intergalactic',
        label: 'intergalactic'
      },
      {
        name: 'late-night',
        label: 'late night'
      },
      {
        name: 'loony',
        label: 'loony'
      },
      {
        name: 'musical',
        label: 'musical'
      },
      {
        name: 'okaaayyy',
        label: 'okaaayyy'
      },
      {
        name: 'out-of-this-world',
        label: 'out of this world'
      },
      {
        name: 'packed-with-pepperoni',
        label: 'packed with pepperoni'
      },
      {
        name: 'paranormal',
        label: 'paranmormal'
      },
      {
        name: 'pickled',
        label: 'pickled'
      },
      {
        name: 'precious',
        label: 'precious'
      },
      {
        name: 'questionable',
        label: 'questionable'
      },
      {
        name: 'recreational',
        label: 'recreational'
      },
      {
        name: 'rosey',
        label: 'rosey'
      },
      {
        name: 'sideways',
        label: 'sideways'
      },
      {
        name: 'spiteful',
        label: 'spiteful'
      },
      {
        name: 'stupid',
        label: 'stupid'
      },
      {
        name: 'tense',
        label: 'tense'
      },
      {
        name: 'terrifying',
        label: 'terrifying'
      },
      {
        name: 'timeless',
        label: 'timeless'
      },
      {
        name: 'true',
        label: 'true'
      },
      {
        name: 'with-a-wingman',
        label: 'with a wingman'
      },
      {
        name: 'with-two-bobs',
        label: 'with two bobs'
      }
    ],
    background: ""
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  randomize() {
    const item = this.state.backgrounds[Math.floor(Math.random() * this.state.backgrounds.length)]
    this.setState({
      background: item.name
    })
  }
  handleSubmit(event) {
    event.preventDefault()
    if (this.state.background === '') {
      this.setState({
        background: 'a-battle'
      })
    }
  }
  restart() {
    this.setState({
      background: ''
    })
  }
  render() {
    return (
      <div>
        <SEO title="Backdrops by Armor"></SEO>
        {this.state.background.length !== 0 &&
          <div>
            <div style={Object.assign({}, 
            pageStyles, 
            { backgroundImage: `url(../../backgrounds/${this.state.background}.png)` })}>
              <Frame>
                <FrameWrapper>
                  <p style={frameTitleStyles}>Take back the backdrop.</p>
                  <p style={frameTextStyles}>Your next meeting will be {this.state.backgrounds.find(x => x.name === this.state.background).label}.</p>
                  <p style={frameBodyStyles}>It looks great on you. Really. You are all set for your call.</p>
                  <FrameButtons>
                    <FrameButtonsItem>
                      <ButtonA href={`/../../backgrounds/${this.state.background}.png`} download>
                        <span style={buttonTextStyles}>Download Image</span>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.47 18.53a.75.75 0 001.06 0l4.773-4.773a.75.75 0 00-1.06-1.06L13 16.939l-4.243-4.242a.75.75 0 10-1.06 1.06l4.773 4.773zM12.25 7v11h1.5V7h-1.5z" fill="#fff"/>
                          <circle cx="13" cy="13" r="12.25" stroke="#fff" strokeWidth="1.5"/>
                        </svg>
                      </ButtonA>
                    </FrameButtonsItem>
                    <FrameButtonsItem>
                      <ButtonSecondary onClick={this.restart}>
                        Start over
                      </ButtonSecondary>
                    </FrameButtonsItem>
                  </FrameButtons>
                </FrameWrapper>
              </Frame>
            </div>
          </div>
        }
        {this.state.background.length === 0 &&
          <main style={pageStyles}>
            <ContainerOne>
              <Lede>
                <HeadingOne>Meeting in 10 minutes?</HeadingOne>
                <TitleOne>Take back the backdrop.</TitleOne>
                <LedeOne>Break free of the perfectly curated bookshelf, the generic beach shots and the cardboard cutout coffee shop*. If we’re going to be sitting on video calls all day, let’s at least make it fun.</LedeOne>
              </Lede>
              <div>
                <form onSubmit={this.handleSubmit}>
                  <FormLabel htmlFor="background">I want my next meeting to be...</FormLabel>
                  <FormGroup>
                    <FormSelectWrapper>
                      <select style={formSelectStyles} name="background" id="background" onBlur={this.handleInputChange}>
                        <option value="a-battle">a battle.</option>
                        <option value="a-joke">a joke.</option>
                        <option value="about-nothing">about nothing.</option>
                        <option value="animated">animated.</option>
                        <option value="at-a-bar">at a bar.</option>
                        <option value="at-moes">at moes.</option>
                        <option value="barefoot">barefoot.</option>
                        <option value="between-two-ferns">between two ferns.</option>
                        <option value="big-enough">big enough.</option>
                        <option value="bouncy">bouncy.</option>
                        <option value="caffeinated">caffeinated.</option>
                        <option value="cooked">cooked.</option>
                        <option value="corny">corny.</option>
                        <option value="delicious">delicious.</option>
                        <option value="distractionless">distractionless.</option>
                        <option value="empty">empty.</option>
                        <option value="excellent">excellent.</option>
                        <option value="fine">fine.</option>
                        <option value="go-15-rounds">go 15 rounds.</option>
                        <option value="golden">golden.</option>
                        <option value="great-again">great again.</option>
                        <option value="grilled">grilled.</option>
                        <option value="hairy">hairy.</option>
                        <option value="intergalactic">intergalactic.</option>
                        <option value="late-night">late night.</option>
                        <option value="loony">loony.</option>
                        <option value="musical">musical.</option>
                        <option value="okaaayyy">okaaayyy.</option>
                        <option value="out-of-this-world">out of this world.</option>
                        <option value="packed-with-pepperoni">packed with pepperoni.</option>
                        <option value="paranormal">paranmormal.</option>
                        <option value="pickled">pickled.</option>
                        <option value="questionable">questionable.</option>
                        <option value="recreational">recreational.</option>
                        <option value="rosey">rosey.</option>
                        <option value="sideways">sideways.</option>
                        <option value="spiteful">spiteful.</option>
                        <option value="stupid">stupid.</option>
                        <option value="tense">tense.</option>
                        <option value="terrifying">terrifying.</option>
                        <option value="timeless">timeless.</option>
                        <option value="true">true.</option>
                        <option value="with-a-wingman">with a wingman.</option>
                        <option value="with-two-bobs">with two bobs.</option>
                      </select>
                    </FormSelectWrapper>
                    <Button>
                      <span style={buttonTextStyles}>Submit</span>
                      <svg width="36" height="12" viewBox="0 0 36 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35.53 6.53a.75.75 0 000-1.06L30.757.697a.75.75 0 00-1.06 1.06L33.939 6l-4.242 4.243a.75.75 0 001.06 1.06L35.53 6.53zM0 6.75h35v-1.5H0v1.5z" fill="#fff"/></svg>
                    </Button>
                  </FormGroup>
                </form>
              </div>
            </ContainerOne>
          </main>
        }
        <Random>
          <RandomWrapper>
            <RandomBadgeOne>
              <Badge></Badge>
            </RandomBadgeOne>
            <RandomBadgeTwo>
              <RandomButton onClick={this.randomize}>
                <span style={srOnly}>Surprise me</span>
                <img style={surpriseBadgeImage} src={`../../surprise.png`} alt="Surprise badge" />
              </RandomButton>
            </RandomBadgeTwo>
          </RandomWrapper>
        </Random>
        <Footer></Footer>
      </div>
    )
  }
}
